import * as React from 'react'
import * as d3 from 'd3'
import Layout from '../../components/layout'
import LineChart from '../../charts/lines/line-chart';
import { ExplanatoryText, ScrollContainer, ScrollArticle, ScrollStep } from '../../components/scrolly'
import ContainerCentered from '../../components/container-centered';
import Header6xl from '../../components/header-6xl';


const homeHeatingEmissions = () => {

  const deepEarly = [
    {
      "x": 2022,
      "y": 50000000000
    },
    {
      "x": 2023,
      "y": 98000000000
    },
    {
      "x": 2024,
      "y": 144000000000
    },
    {
      "x": 2025,
      "y": 188000000000
    },
    {
      "x": 2026,
      "y": 230000000000
    },
    {
      "x": 2027,
      "y": 270000000000
    },
    {
      "x": 2028,
      "y": 308000000000
    },
    {
      "x": 2029,
      "y": 344000000000
    },
    {
      "x": 2030,
      "y": 378000000000
    },
    {
      "x": 2031,
      "y": 410000000000
    },
    {
      "x": 2032,
      "y": 440000000000
    },
    {
      "x": 2033,
      "y": 468000000000
    },
    {
      "x": 2034,
      "y": 494000000000
    },
    {
      "x": 2035,
      "y": 518000000000
    },
    {
      "x": 2036,
      "y": 540000000000
    },
    {
      "x": 2037,
      "y": 560000000000
    },
    {
      "x": 2038,
      "y": 578000000000
    },
    {
      "x": 2039,
      "y": 594000000000
    },
    {
      "x": 2040,
      "y": 608000000000
    },
    {
      "x": 2041,
      "y": 620000000000
    },
    {
      "x": 2042,
      "y": 630000000000
    },
    {
      "x": 2043,
      "y": 638000000000
    },
    {
      "x": 2044,
      "y": 644000000000
    },
    {
      "x": 2045,
      "y": 648000000000
    },
    {
      "x": 2046,
      "y": 650000000000
    },
    {
      "x": 2047,
      "y": 650000000000
    },
    {
      "x": 2048,
      "y": 650000000000
    },
    {
      "x": 2049,
      "y": 650000000000
    },
    {
      "x": 2050,
      "y": 650000000000
    },
    {
      "x": 2051,
      "y": 650000000000
    },
    {
      "x": 2052,
      "y": 650000000000
    },
    {
      "x": 2053,
      "y": 650000000000
    },
    {
      "x": 2054,
      "y": 650000000000
    },
    {
      "x": 2055,
      "y": 650000000000
    },
    {
      "x": 2056,
      "y": 650000000000
    },
    {
      "x": 2057,
      "y": 650000000000
    },
    {
      "x": 2058,
      "y": 650000000000
    },
    {
      "x": 2059,
      "y": 650000000000
    },
    {
      "x": 2060,
      "y": 650000000000
    }
  ]

  const emissionsAccessor = d => d.y
  const dateParser = d3.timeParse("%Y")
  const yearAccessor = d => dateParser(d.x)

  return (
    
    <Layout>
      <head>
        <meta name="robots" content="noindex" />
      </head>

      <section id="hero">
        <ContainerCentered bgColor={"bg-slate-100"} minHeight={"min-h-screen"} maxWidth={"max-w-4xl"}>
          <Header6xl textAlign={"text-center"}>The difference between 1.5 and 2 degrees</Header6xl>
          
        </ContainerCentered>
      </section>
      <section className='mt-16'>
        <ExplanatoryText>
          <h2>Introduction</h2>
          <p>
            In 2021, all the people, corporations, and cow burps in the world emitted 
            about 50 billion tons of carbon dioxide and other greenhouse gases into the atmosphere. 
          </p>
          <p>
          Now imagine two different paths forward. In the first scenario, we all get our act together 
          and start cutting emissions at the pace of 2 billion tons every year for the next 30 years. 
          As a result we reach zero-emissions by 2047.
          </p>
        </ExplanatoryText>
        <ScrollContainer>
          <LineChart
            chartTitle={"Cumulative CO2 emissions"}
            yAccessor={emissionsAccessor}
            xAccessor={yearAccessor}
            data={deepEarly}
            domainLower={0}
            domainUpper={800000000000}
            yFormat={".2s"}
            containerStyling={"sticky mx-auto mb-10vh w-3/4 top-10vh h-50vh bottom-40vh sm:top-10vh sm:h-70vh sm:bottom-20vh"}
            lineColor={"#2F394B"}
            lineWidth={2}
          />
          <ScrollArticle>
              <ScrollStep>
                <p>Coal emissions began declining in America for two main reasons: </p>
                <ol>
                  <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                  <li> The dramatic fall in the price of renewable energy.</li>
                </ol>
                <p> Let's look at how those two changes affected coal emissions.</p>
              </ScrollStep>
              <ScrollStep>
                <p>Coal emissions began declining in America for two main reasons: </p>
                <ol>
                  <li>The fracking boom in America that led to a fall in natural gas prices; </li>
                  <li> The dramatic fall in the price of renewable energy.</li>
                </ol>
                <p> Let's look at how those two changes affected coal emissions.</p>
              </ScrollStep>
            </ScrollArticle>
        </ScrollContainer>
      </section>
      

    </Layout>
  )

}

export default homeHeatingEmissions